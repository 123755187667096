import axios from "axios";

const url = window.location.href;

let BASE_URL: string | undefined = "";
if(url.includes("www."))
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
else if(url.includes("wwwqa."))
  BASE_URL = process.env.REACT_APP_BASE_URL_QA
else
  BASE_URL = process.env.REACT_APP_BASE_URL_DEV

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

// Show the PleaseWait component
const showPleaseWait = () => {
    const pleaseWaitElement = document.getElementById('please-wait');
    if (pleaseWaitElement) {
        pleaseWaitElement.style.display = 'block';
        pleaseWaitElement.style.zIndex = '9999';
    }
    document.body.style.pointerEvents = 'none'; // Disable mouse clicks
};

// Hide the PleaseWait component
const hidePleaseWait = () => {
    const pleaseWaitElement = document.getElementById('please-wait');
    if (pleaseWaitElement) {
        pleaseWaitElement.style.display = 'none';
    }
    document.body.style.pointerEvents = 'auto'; // Enable mouse clicks
};

//request interceptor
http.interceptors.request.use((config) => {
      document.body.style.cursor = 'wait';
      showPleaseWait();
      return config;
    }
);

//response interceptor
http.interceptors.response.use(
  response => {
    document.body.style.cursor = 'default';
    hidePleaseWait();
    return response
  },
  function (error) {
    document.body.style.cursor = 'default';
    hidePleaseWait();
    return Promise.reject(error)
  }
);

export default http;